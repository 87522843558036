@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #000;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
  background: url(../images/common/bg_body.gif);
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  padding-bottom: 40px;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 830px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
  float: none;
  margin: 0 !important;
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  font-family: source-han-serif-japanese, serif;
  font-style: normal;
  font-weight: bold;
}

@media only screen and (max-width: 830px) {
  header#global_header {
    padding-top: 48px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

#header {
  width: 100%;
  background: #892141;
  color: #fff;
  padding: 10px;
}

#header .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  #header .inner {
    display: block;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header .inner {
    padding: 10px;
  }
}

#header .inner ul li {
  text-align: left;
  font-size: 15px;
}

#header .inner ul li:first-child {
  text-align: center;
  background: #580d24;
}

@media only screen and (max-width: 640px) {
  #header #siteID {
    padding: 10px 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header #siteID {
    padding: 20px 0 !important;
  }
}

#header #siteID a {
  opacity: 1;
}

#header #hed_info {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  #header #hed_info {
    justify-content: center;
  }
  #header #hed_info .cont_txt {
    display: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header #hed_info {
    justify-content: center;
  }
}

#header #hed_info .time {
  padding-right: 30px;
}

#header .tel2 {
  font-size: 15px;
}

#header .tel2 i {
  display: none;
}

@media only screen and (max-width: 640px) {
  #header .tel2 i {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  #header .tel2 {
    position: fixed;
    z-index: 8;
    top: 8px;
    left: 8px;
    font-size: 26px;
  }
  #header .tel2 span {
    display: none;
  }
  #header .tel2 a {
    color: #fff;
  }
}

#header .cont {
  font-size: 20px;
}

#header .cont p {
  padding: 16px 32px;
  background: #b94047;
}

#header .cont a {
  color: #fff;
}

#nav_global {
  width: 100%;
  padding: 10px 0;
  background: url(../images/common/nav_bg.jpg);
  border-top: 2px solid #a2842b;
  border-bottom: 2px solid #a2842b;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

#nav_global ul li {
  border-left: 1px dotted #fff;
  width: 100%;
}

#nav_global ul li:last-child {
  border-right: 1px dotted #fff;
}

#nav_global ul li a {
  font-size: 18px;
  display: block;
  color: #fff;
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #nav_global ul li a {
    font-size: 14px;
  }
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#nav_global ul li a span {
  display: block;
  font-family: 'Courgette', cursive;
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 830px) {
  .pcnone {
    display: block;
  }
}

#hed_top {
  background: url(../images/common/hed_bg.jpg);
  font-family: 'Noto Serif Japanese';
  padding: 5px 0;
  border-bottom: 2px solid #a2842b;
}

@media only screen and (max-width: 830px) {
  #hed_top {
    background: none;
    border-bottom: none;
  }
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  #hed_top {
    padding: 5px;
  }
}

#hed_top .hedtop_wrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 830px) {
  #hed_top .hedtop_wrap {
    display: block;
  }
}

#hed_top .hedtop_wrap .hed_topL {
  font-size: 12px;
  color: #fff;
}

@media only screen and (max-width: 830px) {
  #hed_top .hedtop_wrap .hed_topL {
    text-align: center;
    color: #333;
  }
}

@media only screen and (max-width: 830px) {
  #hed_top .hedtop_wrap .hed_topR {
    display: none;
  }
}

#hed_top .hedtop_wrap .hed_topR ul {
  display: flex;
}

#hed_top .hedtop_wrap .hed_topR ul li {
  border-left: 1px solid #fff;
}

#hed_top .hedtop_wrap .hed_topR ul li:first-child {
  border-left: none;
}

#hed_top .hedtop_wrap .hed_topR ul li a {
  color: #fff;
  font-size: 12px;
  padding: 0 10px;
}

#mainArea {
  width: 100%;
}

#mainArea img {
  width: 100% !important;
  height: auto;
}

@media only screen and (max-width: 640px) {
  #mainArea img {
    min-height: 60vw !important;
    object-fit: cover;
  }
}

.slick-initialized .slick-slide {
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-position: left top;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #892141;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-align: left;
  text-shadow: 2px 2px 10px #fff;
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #local-keyvisual h1 {
    font-size: 2.1em;
  }
  #local-keyvisual h1 span {
    padding: 60px 0 !important;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 1.7em;
  }
  #local-keyvisual h1 span {
    padding: 50px 0 !important;
  }
}

#local-keyvisual h1 span {
  display: block;
  padding: 100px 0;
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual h1 span span {
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
}

#local-keyvisual h1 span:before {
  content: '';
  position: absolute;
  bottom: 50px;
  display: inline-block;
  width: 60px;
  height: 2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #a2842b;
  border-radius: 2px;
}

@media only screen and (max-width: 830px) {
  #local-keyvisual h1 span:before {
    bottom: 30px;
  }
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: left top;
}

.pan1 {
  margin: 20px 0;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.mean-container .mean-bar {
  background: url(../images/common/hed_bg.jpg) !important;
  position: fixed !important;
  z-index: 8 !important;
}

.mean-container .mean-nav {
  background: #892141 !important;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid #fff !important;
  text-align: center !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  /*background-color: #e9832f !important;*/
}

.mean-container .mean-nav ul li a {
  padding: 15px 5% !important;
}

.mean-container .mean-nav ul li a i {
  margin-right: 15px;
  font-size: 16px;
}

.mean-container a.meanmenu-reveal span {
  background: #fff !important;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  background: url(../images/common/footer_bg.jpg);
  margin-top: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  border-top: 2px solid #810303;
}

@media screen and (min-width: 830px) and (max-width: 1200px) {
  footer#global_footer {
    padding: 10px;
  }
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: underline;
}

footer#global_footer #nav_footer {
  display: flex;
  justify-content: space-between;
}

footer#global_footer #nav_footer ul li {
  text-align: left;
}

footer#global_footer #nav_footer ul li a:before {
  content: '|';
  color: #fff;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer li a {
  color: #fff;
  text-decoration: none;
  padding: 3px;
  display: block;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

footer#global_footer small {
  color: #fff;
}

#footer {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 830px) {
  #footer {
    display: block;
  }
  #footer .tel2 a {
    color: #fff;
  }
}

#footer .ft_left {
  color: #fff;
}

#footer .ft_left ul li {
  text-align: left;
}

@media only screen and (max-width: 830px) {
  #footer .ft_left ul li {
    text-align: center;
  }
}

#footer .ft_left ul li:first-child {
  margin-bottom: 10px;
}

.totop {
  text-align: right;
}

.h2_01 h2 {
  color: #752712;
  font-size: 25px;
  font-family: source-han-serif-japanese, serif;
  text-shadow: 3px 3px 4px #fff;
}

@media only screen and (max-width: 830px) {
  .h2_01 h2 {
    padding: 10px;
    border-bottom: 1px dotted #752712;
    font-size: 22px;
  }
}

@media screen and (min-width: 830px) and (max-width: 1000px) {
  .h2_01 h2 {
    padding: 10px;
    border-bottom: 1px dotted #752712;
    font-size: 22px;
  }
}

.h2_01 h2:after {
  content: url(../images/common/underline.png);
}

@media only screen and (max-width: 830px) {
  .h2_01 h2:after {
    display: none;
  }
}

@media screen and (min-width: 830px) and (max-width: 1000px) {
  .h2_01 h2:after {
    display: none;
  }
}

.h2_02 h2 {
  font-size: 24px;
  color: #5e280b;
  font-family: source-han-serif-japanese, serif;
}

@media only screen and (max-width: 830px) {
  .h2_02 h2 {
    padding: 10px;
    border-bottom: 1px dotted #5e280b;
    font-size: 22px;
  }
}

@media screen and (min-width: 830px) and (max-width: 1000px) {
  .h2_02 h2 {
    padding: 10px;
    border-bottom: 1px dotted #5e280b;
    font-size: 22px;
  }
}

.h2_02 h2:after {
  content: url(../images/common/info.png);
}

@media only screen and (max-width: 830px) {
  .h2_02 h2:after {
    display: none;
  }
}

@media screen and (min-width: 830px) and (max-width: 1000px) {
  .h2_02 h2:after {
    display: none;
  }
}

.sec_01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_01 {
    display: block;
  }
  .sec_01 .flxL {
    width: 100% !important;
  }
  .sec_01 .flxR {
    width: 100% !important;
  }
}

.sec_01 .flxL {
  width: 48%;
}

.sec_01 .flxL .img {
  border: 5px solid #781936;
}

.sec_01 .flxR {
  width: 48%;
}

.sec_02 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
  }
}

.sec_02 .flxL {
  width: 37%;
}

.sec_02 .flxR {
  width: 57%;
}

.sec_03 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .flxL {
    width: 100% !important;
  }
  .sec_03 .flxR {
    width: 100% !important;
    margin-top: 40px;
  }
}

.sec_03 .flxL {
  width: 48%;
  background: url(../images/common/info_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px;
  position: relative;
}

.sec_03 .flxL .pin1 {
  position: absolute;
  top: -2px;
  left: 17px;
}

.sec_03 .flxL .pin2 {
  position: absolute;
  top: -2px;
  right: 0;
}

.sec_03 .flxL > div {
  background: #fff;
  padding: 30px;
}

.sec_03 .flxR {
  width: 48%;
  background: url(../images/common/info_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px;
  position: relative;
}

.sec_03 .flxR .pin1 {
  position: absolute;
  top: -2px;
  left: 17px;
}

.sec_03 .flxR .pin2 {
  position: absolute;
  top: -2px;
  right: 0;
}

.sec_03 .flxR > div {
  background: #fff;
  padding: 30px;
}

.sec_03 .newswrap {
  padding: 20px;
}

.sec_03 .news {
  height: 250px;
  overflow-y: scroll;
}

.sec_03 .news dt {
  border: none !important;
  padding: 0 !important;
  flex-direction: inherit !important;
}

@media only screen and (max-width: 830px) {
  .sec_03 .news dt {
    display: block !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 830px) and (max-width: 1000px) {
  .sec_03 .news dt {
    display: block !important;
    width: 100% !important;
  }
}

.sec_03 .news dd {
  border: none !important;
  padding: 0 !important;
}

@media only screen and (max-width: 830px) {
  .sec_03 .news dd {
    display: block !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 830px) and (max-width: 1000px) {
  .sec_03 .news dd {
    display: block !important;
    width: 100% !important;
  }
}

.sec_03 .news dl {
  border-bottom: 1px dotted #333;
  padding: 15px 0 !important;
}

@media only screen and (max-width: 830px) {
  .sec_03 .news dl {
    display: block !important;
    padding: 10px 0 !important;
  }
}

@media screen and (min-width: 830px) and (max-width: 1000px) {
  .sec_03 .news dl {
    display: block !important;
    padding: 10px 0 !important;
  }
}

.bnrbox {
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .bnrbox {
    display: block;
  }
  .bnrbox .box {
    width: 100% !important;
    margin-top: 30px;
  }
}

.bnrbox .box {
  width: 31%;
}

.bnrbox .box a {
  display: block;
}

/* box */
.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

.h2_03 h2 {
  font-size: 25px;
  border-bottom: dotted 1px #892141;
  border-left: 5px #892141 solid;
  font-family: source-han-serif-japanese, serif;
  padding: 10px;
  background: #f5ebdc;
}

@media only screen and (max-width: 640px) {
  .h2_03 h2 {
    font-size: 22px;
  }
}

.h2_04 h2 {
  font-size: 25px;
  font-family: source-han-serif-japanese, serif;
}

.h2_04 h2:before {
  font-family: "Font Awesome 5 pro";
  content: "\f2e6";
  /*アイコンのユニコード*/
  margin-right: 7px;
  color: #626063;
}

@media only screen and (max-width: 640px) {
  .h2_04 h2 {
    font-size: 22px;
  }
}

.h2_05 h2 {
  font-size: 25px;
  font-family: source-han-serif-japanese, serif;
  border-bottom: 1px dotted #892141;
  padding: 10px;
}

@media only screen and (max-width: 640px) {
  .h2_05 h2 {
    font-size: 22px;
  }
}

.sec_04 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_04 {
    display: block;
  }
  .sec_04 .img {
    width: 100% !important;
  }
  .sec_04 .table_01 {
    width: 100% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .sec_04 {
    display: block;
  }
  .sec_04 .img {
    width: 50% !important;
    margin: 0 auto;
  }
  .sec_04 .table_01 {
    width: 100% !important;
  }
}

.sec_04 .img {
  width: 25%;
}

.sec_04 .img img {
  width: 100% !important;
  border: 5px solid #892141;
}

.sec_04 .table_01 {
  width: 60%;
}

.sec_04 .table_01 dt {
  border: none !important;
}

.sec_04 .table_01 dd {
  border: none !important;
  background: none !important;
}

.sec_04 .table_01 dl {
  border-bottom: 1px dotted #626063;
}

.sec_05 {
  background: #f5ebdc;
  padding: 30px;
}

.sec_06 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_06 {
    display: block;
  }
  .sec_06 .img {
    width: 100% !important;
  }
  .sec_06 .txt {
    width: 100% !important;
  }
}

.sec_06 .img {
  width: 48%;
}

.sec_06 .txt {
  width: 48%;
}

.sec_07 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sec_08 {
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_08 {
    background-size: cover;
  }
}

.sec_08 .txt_L {
  color: #d00b44;
  font-weight: bold;
  font-size: 30px;
  border-right: 1px dotted #999 !important;
  font-family: source-han-serif-japanese, serif;
  width: 38%;
  padding: 72px 0;
}

@media only screen and (max-width: 640px) {
  .sec_08 .txt_L {
    padding: 0;
  }
}

.sec_08 .txt_R {
  width: 54%;
  padding: 33px 71px 33px 10px;
}

@media only screen and (max-width: 640px) {
  .sec_08 .txt_R {
    padding: 8px;
    font-size: 13px;
  }
}

.sec_09 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_09 {
    display: block;
  }
  .sec_09 .img {
    width: 100% !important;
  }
  .sec_09 .txt {
    width: 100% !important;
  }
}

.sec_09 .tel2 {
  font-size: 30px;
  color: #781936;
}

.sec_09 .tel2 a {
  color: #781936;
}

.sec_09 .img {
  width: 40%;
}

.sec_09 .img img {
  border: 5px solid #781936;
}

.sec_09 .txt {
  width: 48%;
}

.txt_page {
  border-left: 5px solid #fff;
  margin: 10px 0;
  padding-left: 7px;
  font-size: 20px;
}

.txt_page a {
  color: #781936;
}

.txt_page a:hover {
  text-decoration: underline;
}

.sec_06wrap {
  background: url(../images/common/info_bg.jpg);
  padding: 20px;
  background-size: cover;
}

.sec_06bg {
  background: #f5ebdc;
  padding: 30px;
}

.txt_menu {
  border-bottom: 1px dotted #a2842b;
  padding: 10px;
}

.txt_price {
  font-size: 20px;
}

.txt_price span {
  font-size: 16px;
}

.gmap iframe {
  width: 100%;
}

/* title */
.bg_100per_wrap {
  position: relative;
  padding: 100px 0 !important;
}

@media only screen and (max-width: 830px) {
  .bg_100per_wrap {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont_bg.jpg);
  background-size: cover;
  z-index: -1;
}
